import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import tradeObjects from '@/router/trade-objects';

const SignInView = () => import('@/views/sign-in.vue');
const AppView = () => import('@/views/app.vue');
const AppIndexView = () => import('@/views/app/index.vue');

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'index',
    redirect: { path: '/app' },
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: SignInView,
  },
  {
    path: '/app',
    component: AppView,
    children: [
      {
        path: '',
        name: 'app-index',
        component: AppIndexView,
        redirect: { name: 'trade-objects-index' },
        children: [...tradeObjects],
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
