export interface ITokens {
  accessToken: string;
  accessTokenTTL: Date;
  refreshToken: string;
  refreshTokenTTL: Date;
}

export interface IUser {
  username: string;
  roles: string[];
}

export enum UserRoles {
  ROLE_TAX_INSPECTOR = 'ROLE_TAX_INSPECTOR',
  ROLE_B2B_INSPECTOR = 'ROLE_B2B_INSPECTOR',
  ROLE_B2B_MANAGER = 'ROLE_B2B_MANAGER',
  ROLE_DDA_ADMINISTRATOR = 'ROLE_DDA_ADMINISTRATOR',
  ROLE_AKIMAT_MANAGER = 'ROLE_AKIMAT_MANAGER',
  ROLE_AKIMAT_INSPECTOR = 'ROLE_AKIMAT_INSPECTOR',
  ROLE_TAX_ADMINISTRATOR = 'ROLE_TAX_ADMINISTRATOR',
}

export interface IKeycloakUserDto {
  username: string;
  firstName: string;
  lastName: string;
  roles: UserRoles[];
}
