import axios from 'axios';
import { interceptor } from '@/api/interceptors';

function getBaseURL(): string {
  if (process.env.NODE_ENV === 'production') {
    return process.env.VUE_APP_REQUEST_APP_HOST + '/identity/client';
  }

  return 'https://sto-kkm-ui-ais-no.dev-24x7.com/identity/resource-server/';
}

const api = axios.create({
  baseURL: getBaseURL(),
});

api.interceptors.request.use(
  (config) => {
    // const accessToken = sessionService.tokens?.accessToken || '';
    // config.headers.set('Access-Token', accessToken, true);
    return config;
  },
  (err) => err
);

api.interceptors.response.use(
  interceptor.onFulfilled.bind(interceptor),
  interceptor.onRejected.bind(interceptor)
);

const authApi = axios.create({
  baseURL: process.env.VUE_APP_IDENTITY_GATEWAY,
  withCredentials: true,
});

export { api, authApi };
