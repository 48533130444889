import { IInterceptor } from '@/api/interceptors/IInterceptor';
import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { sessionService } from '@/services/session';
import router from '@/router';
import { api } from '@/api';

export default class TokenInterceptor implements IInterceptor {
  private _isRefreshing = false;
  private _refreshStack: InternalAxiosRequestConfig<any>[] = [];

  onFulfilled(response: AxiosResponse<any, any>): AxiosResponse<any, any> {
    return response;
  }

  onRejected(error: AxiosError): any {
    const config = error.config;
    if (error.response?.status === 401 && config) {
      if (sessionService.isRefreshTokenExpired) {
        router.currentRoute.name !== 'sign-in' && router.push('/sign-in');
        return error;
      }

      if (!this._isRefreshing) {
        this._isRefreshing = true;

        return sessionService
          .doRefreshToken()
          .then((tokens) => {
            this._isRefreshing = false;
            const { accessToken } = tokens;
            sessionService.getCurrentUser();
            config.headers.set('Access-Token', accessToken, true);
            this._refreshStack.forEach((cfg) => {
              cfg.headers.set('Access-Token', accessToken, true);
              api.request(cfg);
            });
            return api.request(config);
          })
          .catch(() => {
            router.push('/sign-in');
            return error;
          });
      } else {
        this._refreshStack.push(config);
      }
    }

    return Promise.reject(error);
  }
}
