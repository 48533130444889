import { ISessionService } from '@/services/session/ISessionService';
import { IKeycloakUserDto, ITokens, IUser } from '@/types/session';
import { IUserDto, User } from '@/models/User';
import { authApi } from '@/api';

export default class KeycloakSessionService implements ISessionService {
  public get tokens(): ITokens | undefined {
    const now = new Date();

    return {
      accessToken: 'fake-accessToken',
      accessTokenTTL: new Date(
        now.getFullYear() + 50,
        now.getMonth(),
        now.getDate()
      ),
      refreshToken: 'fake-refreshToken',
      refreshTokenTTL: new Date(
        now.getFullYear() + 100,
        now.getMonth(),
        now.getDate()
      ),
    };
  }

  public set tokens(tokens: ITokens | undefined) {
    // @ts-ignore
  }

  public get isTokenExpired(): boolean {
    return false;
  }

  public get isRefreshTokenExpired(): boolean {
    return false;
  }

  public open(tokens: ITokens): void {
    // @ts-ignore
  }

  public async close(): Promise<void> {
    try {
      User.clearInstance();
      await authApi.post('/authentication/logout');
    } catch (e) {
      console.error(e);
    } finally {
      this.redirectToAuth();
    }
  }

  public async getCurrentUser(): Promise<User> {
    try {
      const response = await authApi.get<IKeycloakUserDto>(
        '/authentication/user'
      );
      if (response.data == null) {
        this.redirectToAuth();
        return User.getInstance(undefined);
      }

      const userDto = response.data;
      const user: IUserDto = {
        username: userDto.username,
        roles: [...userDto.roles],
        family_name: '',
        given_name: '',
      };

      return User.getInstance(user);
    } catch (e) {
      console.error(e);
      this.redirectToAuth();
      return User.getInstance(undefined);
    }
  }

  public async doRefreshToken(): Promise<ITokens> {
    const now = new Date();
    const tokens: ITokens = {
      accessToken: 'fake-accessToken',
      accessTokenTTL: new Date(
        now.getFullYear() + 50,
        now.getMonth(),
        now.getDate()
      ),
      refreshToken: 'fake-refreshToken',
      refreshTokenTTL: new Date(
        now.getFullYear() + 100,
        now.getMonth(),
        now.getDate()
      ),
    };

    return Promise.resolve(tokens);
  }

  public redirectToAuth(): void {
    location.href = authApi.defaults.baseURL + '/oauth2/authorization/keycloak';
  }
}
