import { IUser, UserRoles } from '@/types/session';
import { observable } from 'mobx';
import { authApi } from '@/api';

export interface IUserDto {
  username: string;
  family_name: string;
  given_name: string;
  roles: UserRoles[];
}

export interface ITokensDto {
  access_token: string;
  refresh_token: string;
}

export interface IUserWithTokensDto {
  username: string;
  family_name: string;
  given_name: string;
  realm_roles: UserRoles[];
}

export class User implements IUserDto {
  @observable static _instance?: User;

  protected static instance?: User;

  public roles: UserRoles[];
  public username: string;
  public family_name: string;
  public given_name: string;

  public static getInstance(dto?: IUserDto) {
    if (dto) User.instance = new User(dto);
    if (!User.instance && process.env.NODE_ENV === 'production') {
      console.log(User.instance, process.env.NODE_ENV);
      // User.redirectToAuth();
    }
    if (process.env.NODE_ENV === 'development')
      User.instance = new User({
        roles: [UserRoles.ROLE_TAX_INSPECTOR],
        username: '',
        given_name: '',
        family_name: '',
      });
    return User.instance!;
  }

  public static clearInstance(): void {
    this._instance = undefined;
  }

  public get fullName() {
    return `${this.given_name} ${this.family_name}`;
  }

  public get hasAccess() {
    const userRolesArray: UserRoles[] = Object.values(UserRoles);
    return userRolesArray.some((role) => this.roles.includes(role));
  }

  // public static getInstance(dto?: IUserDto) {
  //   if (dto) User.instance = new User(dto);
  //   if (!User.instance && process.env.NODE_ENV === 'production')
  //     User.redirectToAuth();
  //   if (process.env.NODE_ENV === 'development')
  //     User.instance = new User({
  //       firstName: '',
  //       lastName: '',
  //       roles: [UserRoles.ROLE_MANAGER],
  //       username: '',
  //     });
  //   return User.instance!;
  // }

  public static async auth(token: string) {
    try {
      const response = await authApi.get<IUserDto>('/authentication/user');
      return User.getInstance(response.data);
    } catch (e) {
      console.error(e);
      User.redirectToAuth();
    }
  }

  public static async logout() {
    try {
      await authApi.post('/authentication/logout');
    } catch (e) {
      console.error(e);
    } finally {
      User.redirectToAuth();
    }
  }

  public static redirectToAuth(): void {
    console.log('Attempt to redirect to Keycloak auth page');
    const href = authApi.defaults.baseURL + '/oauth2/authorization/keycloak';
    window.location.replace(href);
  }

  protected constructor(dto: IUserDto) {
    this.username = dto.username;
    this.given_name = dto.given_name;
    this.family_name = dto.family_name;
    this.roles = dto.roles;
  }

  /* eslint-disable */
}

export class UserWithToken extends User {
  public static tokens?: ITokensDto;

  public static get accessToken() {
    return this.tokens?.access_token;
  }
  public static getInstance(dto?: IUserDto) {
    if (dto) UserWithToken.instance = new UserWithToken(dto);
    if (!UserWithToken.instance && process.env.NODE_ENV === 'production') {
      // UserWithToken.redirectToAuth();
    }
    if (process.env.NODE_ENV === 'development')
      UserWithToken.instance = new UserWithToken({
        roles: [UserRoles.ROLE_TAX_INSPECTOR],
        username: '',
        given_name: '',
        family_name: '',
      });
    return UserWithToken.instance!;
  }
  public static async auth(token: string) {
    try {
      const tokens = await authApi.get<ITokensDto>(
        '/authentication/user/token',
        {
          headers: {
            'Access-Token': token,
          },
        }
      );
      this.tokens = tokens.data;
      console.log('tokens', tokens.data);
      const response = await authApi.get<IUserWithTokensDto>(
        '/authentication/user/introspect-token',
        {
          headers: {
            Authorization: `Bearer ${tokens.data.access_token}`,
          },
        }
      );

      console.log('introspect', response.data);
      return UserWithToken.getInstance({
        username: response.data.username,
        roles: response.data.realm_roles,
        given_name: response.data.given_name || '',
        family_name: response.data.family_name || '',
      });
    } catch (e) {
      console.error(e);
      // UserWithToken.redirectToAuth();
    }
  }

  public static async logout() {
    try {
      UserWithToken.getInstance().roles = [];
      UserWithToken.tokens = undefined;
      await authApi.post('/authentication/logout');
    } catch (e) {
      console.error(e);
    } finally {
      UserWithToken.redirectToAuth();
    }
  }
}
