import router from '@/router';
import { ITokens } from '@/types/session';
import { IUserDto, User } from '@/models/User';
import { api } from '@/api';
import { ISessionService } from '@/services/session/ISessionService';

export default class SessionService implements ISessionService {
  private readonly TOKENS = 'TOKENS';

  get tokens(): ITokens | undefined {
    const json = localStorage.getItem(this.TOKENS);
    return json ? JSON.parse(json) : undefined;
  }

  set tokens(tokens: ITokens | undefined) {
    if (tokens) {
      localStorage.setItem(this.TOKENS, JSON.stringify(tokens));
    } else {
      localStorage.removeItem(this.TOKENS);
    }
  }

  get isTokenExpired(): boolean {
    const tokens = this.tokens;
    return (
      !tokens ||
      !tokens.accessToken ||
      !tokens.accessTokenTTL ||
      new Date() > new Date(tokens.accessTokenTTL)
    );
  }

  get isRefreshTokenExpired(): boolean {
    const tokens = this.tokens;
    return (
      !tokens ||
      !tokens.refreshToken ||
      !tokens.refreshTokenTTL ||
      new Date() > new Date(tokens.refreshTokenTTL)
    );
  }

  open = (tokens: ITokens): void => {
    this.tokens = tokens;
  };

  close = (): void => {
    this.tokens = undefined;
    User.clearInstance();
  };

  async getCurrentUser(): Promise<User> {
    const response = await api.get<IUserDto>('/tax-web-control/me', {
      headers: { 'Access-Token': this.tokens?.accessToken || '' },
    });
    return User.getInstance(response.data);
  }

  async doRefreshToken() {
    const tokens = this.tokens;
    this.close();
    const response = await api.post<ITokens>('/tax-web-control/refresh', {
      headers: { 'Refresh-Token': tokens?.refreshToken },
    });
    this.open(response.data);
    return response.data;
  }

  redirectToAuth(): void {
    router.push('/sign-in');
  }
}
