import { RouteConfig } from 'vue-router';

const TradeObjectsView = () => import('@/views/app/trade-objects.vue');
const TradeObjectsIndexView = () =>
  import('@/views/app/trade-objects/index.vue');
const TradeObjectsIdIndex = () =>
  import('@/views/app/trade-objects/_id/index.vue');
const TradeObjectsIdInfo = () =>
  import('@/views/app/trade-objects/_id/info.vue');
const TradeObjectsIdRenters = () =>
  import('@/views/app/trade-objects/_id/renters.vue');

const TradePointView = () =>
  import('@/views/app/trade-objects/trade-point.vue');
const TradePointIndexView = () =>
  import('@/views/app/trade-objects/trade-point/index.vue');
const TradePointIdView = () =>
  import('@/views/app/trade-objects/trade-point/_trade-point-id.vue');
const TradePointIdIndexView = () =>
  import('@/views/app/trade-objects/trade-point/_trade-point-id/index.vue');
const TradePointIdReceiptsView = () =>
  import('@/views/app/trade-objects/trade-point/_trade-point-id/receipts.vue');

const routes: Array<RouteConfig> = [
  {
    path: 'trade-objects',
    component: TradeObjectsView,
    children: [
      {
        path: '',
        name: 'trade-objects-index',
        component: TradeObjectsIndexView,
      },
      {
        path: ':tradeObjectId',
        name: 'trade-objects-id-index',
        redirect: { name: 'trade-objects-id-info' },
        component: TradeObjectsIdIndex,
        children: [
          {
            path: 'info',
            name: 'trade-objects-id-info',
            component: TradeObjectsIdInfo,
          },
          {
            path: 'renters',
            name: 'trade-objects-id-renters',
            component: TradeObjectsIdRenters,
          },
        ],
      },
      {
        path: 'trade-point',
        component: TradePointView,
        children: [
          {
            path: '',
            name: 'trade-point-index',
            redirect: { name: 'trade-objects-index' },
            component: TradePointIndexView,
          },
          {
            path: ':tradePointId',
            component: TradePointIdView,
            children: [
              {
                path: '',
                name: 'trade-point-id-index',
                component: TradePointIdIndexView,
              },
              {
                path: 'receipts',
                name: 'trade-point-id-receipts',
                component: TradePointIdReceiptsView,
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
