import { IInterceptor } from '@/api/interceptors/IInterceptor';
import { AxiosError, AxiosResponse } from 'axios';
import { sessionService } from '@/services/session';

export default class KeycloakInterceptor implements IInterceptor {
  onFulfilled(response: AxiosResponse<any, any>): AxiosResponse<any, any> {
    return response;
  }

  onRejected(error: AxiosError): any {
    const config = error.config;
    if (error.response?.status === 401 && config) {
      sessionService.close();
    }
  }
}
