import Vue from 'vue';
import App from './App.vue';
import router from './router';
import DttUiKit from '@dtt/ui-kit';
import 'moment/locale/ru.js';

import 'normalize.css';
import '@/assets/styles/index.scss';
import VTooltip from 'v-tooltip';
import { i18n } from '@/i18n';
import { UserWithToken as User } from '@/models/User';

Vue.config.productionTip = false;

Vue.use(VTooltip, {
  defaultClass: 'tooltip',
});

Vue.use(DttUiKit, { i18n: (key: any, value: any) => i18n.t(key, value) });

const runApp = () => {
  const app = new Vue({
    router,
    i18n,
    render: (h) => h(App),
  });
  app.$mount('#app');
};

const runWithAuth = async () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('token') || localStorage.getItem('token');
  if (!token) {
    await User.logout();
    return;
  }
  localStorage.setItem('token', token);
  const user = await User.auth(token);
  if (!user || !user.hasAccess) {
    await User.logout();
  } else {
    runApp();
  }
};

if (process.env.NODE_ENV === 'production') {
  runWithAuth();
} else {
  runApp();
}
